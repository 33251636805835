/**
 * Get Form Data, and return as an Object
 * @param form
 * @return object
 */
export const getFormData = form => {
    const formData = new FormData(form);
    const obj = {};

    formData.forEach((value, key) => {
        if (obj[key]) {
            if (!obj[key].includes(value)) {
                obj[key] = [].concat(obj[key], value);
            }
        } else {
            obj[key] = value;
        }
    });

    return obj;
}
