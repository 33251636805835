/**
 * CSRF Token Name
 */
const CSRF_TOKEN_NAME = '_token';

/**
 * Returns All App data
 */
module.exports = {
    CSRF_TOKEN_NAME
};
