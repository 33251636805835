
/**
 * Initialize Tooltips
 */
module.exports = () => {
    if (typeof bootstrap === 'undefined' || typeof bootstrap.Tooltip === 'undefined') {
        const Tooltip = require('bootstrap/js/dist/tooltip');

        const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltips].map(tooltipTriggerEl => {
            new Tooltip(tooltipTriggerEl, {
                animation: false,
                trigger: 'hover focus'
            });
        });
    } else {
        const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltips].map(tooltipTriggerEl => {
            bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl, {
                animation: false,
                trigger: 'hover focus'
            });
        });
    }
};
