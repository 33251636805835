import { CSRF_TOKEN_NAME } from '@js/DataMap/App.js';

/**
 * Prepare Form Data (For POST Requests)
 * @param {object} body
 * @param {?string} csrfToken
 * @return {Object}
 */
function prepareFormData({ body = {}, csrfToken = null }) {
    const csrfTokenHiddenElement = document.querySelectorAll(`input[name="${CSRF_TOKEN_NAME}"]`)[0] || null;

    const formData = body;

    if (csrfTokenHiddenElement) {
        formData.append(CSRF_TOKEN_NAME, csrfTokenHiddenElement.value);
    } else if (csrfToken) {
        formData.append(CSRF_TOKEN_NAME, csrfToken);
    }

    return formData;
}

/**
 * Return default headers based on request type
 * @param isPostRequest
 * @param setJSONHeaders
 * @return {{'X-Requested-With': string}|{'Content-Type': string, 'X-Requested-With': string}|{Accept: string, 'X-Requested-With': string}}
 */
function getDefaultHeaders(isPostRequest, setJSONHeaders) {
    if (!setJSONHeaders) {
        return { 'X-Requested-With': 'XMLHttpRequest' };
    }

    return isPostRequest
           ? { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }
           : { 'Accept': 'application/json', 'X-Requested-With': 'XMLHttpRequest' };
}

/**
 * Prepare JSON Data (For POST Requests)
 * @param {object} body
 * @param {?string} csrfToken
 * @return {string}
 */
function prepareJSONData({
    body = {},
    csrfToken = null
} = {}) {
    if (!csrfToken) {
        const csrfTokenHiddenElement = document.querySelectorAll(`input[name="${CSRF_TOKEN_NAME}"]`)[0] || null;
        csrfToken = csrfTokenHiddenElement ? csrfTokenHiddenElement.value : null;
    }

    return JSON.stringify(csrfToken ? { ...body, [CSRF_TOKEN_NAME]: csrfToken } : body);
}

/**
 * Handle Fetch Request
 * @param {string} url                              Fetch request url
 * @param {string} method                           Fetch request method
 * @param {object} headers                          Custom fetch request headers
 * @param {object} body                             Body data to send in request
 * @param {boolean} setJSONHeaders                  Set JSON Content Headers, only where Headers are not explicitly set
 * @param {?string} csrfToken                       CSRF Token Ref
 * @param {function} updateCSRFTokenValue           Update CSRF Token value on Hidden Input
 * @param {boolean} preventDefaultErrorMessage      Prevent Default Error Message on Fetch Request Failed
 * @param {function} onBeforeSend                   Callback function to run before fetch request is sent
 * @param {?function} onResponse                    Callback function to run when there is a response, overriding the default behaviour of returning json response
 * @param {function} onSuccess                      Callback function to run on successful fetch request
 * @param {function} onComplete                     Callback function to run on completed fetch request (successful or failed)
 * @param {function} onFailure                      Callback function to run on failed fetch request
 * @returns {Promise<any>}
 */
export const handleFetchRequest = async ({
    url,
    method = 'GET',
    headers = null,
    signal = null,
    body = {},
    setJSONHeaders = true,
    csrfToken = null,
    preventDefaultErrorMessage = false,
    onBeforeSend = () => {},
    onResponse = null,
    onSuccess = (response) => {},
    onComplete = (response) => {},
    onFailure = (error) => {}
} = {}) => {
    const isPostRequest = method.toUpperCase() === 'POST';

    // Set Request Headers if not explicitly set on the params
    headers = headers ?? getDefaultHeaders(isPostRequest, setJSONHeaders);
    const { 'Content-Type': contentType = null } = headers;
    const isJSONPostRequestContentType = (contentType === 'application/json');

    // Handle before send
    onBeforeSend();

    // Fetch Request Options
    let options = { method, headers, signal };
    if (isPostRequest) {
        if (isJSONPostRequestContentType) {
            options = {
                ...options,
                body: prepareJSONData({
                    body,
                    csrfToken
                })
            };
        } else {
            options = {
                ...options,
                body: prepareFormData({
                    body,
                    csrfToken
                })
            };
        }
    }

    /**
     * Handle regenerating CSRF Token
     * @param {object} responseData
     */
    const regenerateCSRFToken = (responseData) => {
        const {
            [CSRF_TOKEN_NAME]: updatedSecurityToken = null,
            security_token: updatedToken = null
        } = responseData;

        const updatedCSRFToken = updatedSecurityToken || updatedToken;

        if (updatedCSRFToken) {
            const csrfTokenHiddenElements = document.querySelectorAll(`input[name="${CSRF_TOKEN_NAME}"]`);
            csrfTokenHiddenElements.forEach(csrfTokenHiddenElement => {
                csrfTokenHiddenElement.value = updatedCSRFToken
            })
        }
    };

    // Handle fetch request
    return await fetch(url, options)
        .then(async (response) => {
            if (typeof onResponse === 'function') {
                onResponse(response);
            } else {
                if (!response.ok && !preventDefaultErrorMessage) {
                    return response.json().then((responseData) => {
                        if (isPostRequest && responseData) {
                            regenerateCSRFToken(responseData);
                        }
                        throw new Error(responseData?.message || 'Fetch Request Failed');
                    });
                }

                return response.json();
            }
        })
        .then((responseData) => {
            if (isPostRequest && responseData) {
                regenerateCSRFToken(responseData);
            }

            const successResult = onSuccess(responseData);
            const completeResult = onComplete(responseData);
            return successResult ?? completeResult ?? responseData;
        })
        .catch((error) => {
            onFailure(error);
        });
};
